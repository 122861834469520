import React from 'react'

import { CheckoutLayout, CheckoutStepGiftWrapping } from '../../components'

const CheckoutStepGiftWrappingTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  return (
    <CheckoutLayout lang={lang} switcher={page}>
      <CheckoutStepGiftWrapping lang={lang} />
    </CheckoutLayout>
  )
}

export default CheckoutStepGiftWrappingTemplate
